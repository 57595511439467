import React, {useState} from 'react';
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from "components/layout";
import SeoComponent from 'components/seo';
import { useStrapiVideosWebinarsData  } from "hooks";
import { Hero, Breadcrumb, Modal, VideoComponent, Button, ExternalIcon } from "components/ui";
import { Overlay } from "components/global";
import { CardContainer, FlagCardContainer } from "components/containers/Cards";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const groupBy = (array, key) => {
  return array?.reduce((result, currentValue) => {
    (result[currentValue?.category?.name] = result[currentValue?.category?.name] || []).push(
      currentValue
    );
    return result;
  }, {});
};

const TopicEntries = ({ data, location }) => {
  const topic = data?.strapiTopic
  const topicBlocks = topic?.blocks
  const topicResources = [...topic?.resources,...topic?.videos_and_webinars,...topic?.series]
  const groupByCategory = groupBy(topicResources, 'category')
  const categoryResources = Object.entries(groupByCategory).map(([categoryName, resourcesData]) => ({ categoryName, resourcesData }));
  const { allStrapiVideo } = useStrapiVideosWebinarsData();
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [modalDisplay, setModalDisplay] = useState(false);

  const seo = {
    metaTitle: topic?.title,
    metaDescription: topic?.description,
    shareImage: getSrc(topic?.thumbnail?.localFile),
    metaKeywords: topic?.keywords
  }

  let thisLocationPathname = ''

  if (isBrowser) {
      thisLocationPathname = window.location.pathname
  }

  const createKey = title => {
    let keyId = title.toLowerCase().replace(" ", "-");
    return keyId
  }
  const selectedVideo=e=>{
    e.preventDefault();
    setModalDisplay(!modalDisplay);
    let selectedVideo = allStrapiVideo?.nodes.filter(resource=>{
        return resource?.title === e.target.title;
    })
    setSelectedVideoTitle(selectedVideo[0].title);
    selectedVideo[0]?.video_url.includes("=") ? setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url?.indexOf('=')+1)) : setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url.indexOf('.')+4));
}
const updateModalDisplay=e=>{
  e.preventDefault();
  setModalDisplay(!modalDisplay);
}
const sortTopics=topics=>{
  const topicsOrder=["Policy and Practice Resources", "Related Online Training, Videos, and Webinars", "State and Local Examples", "Additional Resources"]
  let sortedTopics=topics.sort((currentTopic,topic)=>topicsOrder.indexOf(currentTopic?.categoryName)-topicsOrder.indexOf(topic?.categoryName))
  return sortedTopics;
}

  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div>
          <Hero
            img={getSrc(topic?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={topic?.title} />
              <h1 className="usa-hero__heading" name="main-content" id="main-content">{topic?.hero_image?.headline}</h1>
              {topic?.hero_image?.description &&
                <div
                  dangerouslySetInnerHTML={{
                  __html: topic?.hero_image?.description?.data?.childMarkdownRemark?.html,
                  }}
                />
              }
            </Hero>
        </div>
        {(topicBlocks && (topicBlocks[0]?.resources?.length > 0 || topicBlocks[0]?.series?.length > 0 || topicBlocks[0]?.videos?.length > 0) ) && topicBlocks.map((block, idx) => {
          return block.strapi_component.indexOf('highlighted')  &&
            <FlagCardContainer
              key={`highlighted-resource-card-group-${idx+1}`}
              title={block.title}
              buttonOnClick={selectedVideo}
              resources={[...block?.resources,...block?.videos,...block?.series]}
              containerStyle="blue-bg"
              headerColor="text-base-lightest"
              keyName="highlighted-resource"
              cardColor="bg-white"
              grid="desktop:grid-col-12 min-height-flag-card-container card-mobile resource-card"
              topicPage={true}
              />
        })}
        {categoryResources && sortTopics(categoryResources)?.map((category, idx) => {
          let bgColorIndex = idx+1;
          return (<CardContainer
            key={`topic-card-group-${idx+1}`}
            title={category?.categoryName}
            buttonOnClick={selectedVideo}
            resources={category?.resourcesData}
            keyName={createKey(category?.categoryName)}
            containerStyle={bgColorIndex % 2 === 0 ? "": "light-gray-bg"}
            cardColor="bg-white"
            grid="desktop:grid-col-6"
            topicPage={true}
            />
          )
        })}
        <Overlay isVisible={modalDisplay} />
        <Modal
          modalId={`topic-page-video-webinar-modal`}
          modalType="usa-modal--lg radius-lg"
          modalDisplay={modalDisplay}
        >
          <Modal.ModalButton closeModal={updateModalDisplay}/>
            <Modal.ModalBody modalBodyClass="text-center">
              <div className="padding-top-6">
                <VideoComponent
                  videoSrcId={selectedVideoId}
                  videoTitle={selectedVideoTitle}
                />
              </div>
            </Modal.ModalBody>
            <Modal.ModalFooter modalFooterClass="text-center">
              <Button color="primary-button" size="width-card-lg margin-bottom-3" closeModal={true} onClick={updateModalDisplay}>
                Close
              </Button>
            </Modal.ModalFooter>
          </Modal>
          {topic?.slug === "fentanyl" &&
          <div className="grid-container padding-bottom-5" id="footnotes">
              <hr />
              <p>[1] National Institute on Drug Abuse. (2022). Overdose Death Rates. <a href="https://nida.nih.gov/research-topics/trends-statistics/overdose-death-rates" target="_blank" rel="noreferrer" className="">Drug Overdose Death Rates | National Institute on Drug Abuse</a><ExternalIcon /></p>
          </div>}
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiTopic(slug: {eq: $slug}) {
      id
      slug
      title
      description
      keywords
      thumbnail {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      hero_image {
        headline
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        background_image {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      blocks {
        strapi_component
        title
        resources {
          slug
          title
          card_title
          author
          external_resource
          external_url_resource
          external_file_size
          open_external_in_new_window
          resource_date(formatString: "MMMM DD, YYYY")
          body {
            data {
              childMarkdownRemark {
                html
                excerpt(format: HTML, pruneLength: 146)
              }
            }
          }
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          pdf {
            name
            localFile {
              prettySize
            }
          }
        }
        videos {
          slug
          title
          card_title
          external_resource
          category {
            name
          }
          author
          date(formatString: "MMMM DD, YYYY")
          body {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                excerpt(format: HTML, pruneLength: 136)
              }
            }
          }
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77, width: 205)
              }
            }
          }
          video_url
        }
        series {
          slug
          title
          card_title
          category {
            name
          }
          author
          resource_date(formatString: "MMMM DD, YYYY")
          thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          body {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                excerpt(format: HTML, pruneLength: 136)
              }
            }
          }
          resources {
            slug
          }
          videos_webinars_series {
            slug
          }
        }
      }
      resources {
        slug
        title
        card_title
        category {
          name
        }
        series{
          title
        }
        author
        external_resource
        external_url_resource
        external_file_size
        open_external_in_new_window
        resource_date(formatString: "MMMM DD, YYYY")
        body {
          data {
            childMarkdownRemark {
              html
              rawMarkdownBody
              excerpt(format: HTML, pruneLength: 85)
            }
          }
        }
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 205 height: 265)
            }
          }
        }
        pdf {
          name
          localFile {
            prettySize
          }
        }
      }
      videos_and_webinars {
        slug
        title
        card_title
        external_resource
        category {
          name
        }
        series{
          title
        }
        author
        date(formatString: "MMMM DD, YYYY")
        body {
          data {
            childMarkdownRemark {
              rawMarkdownBody
              excerpt(format: HTML, pruneLength: 136)
            }
          }
        }
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.77, width: 205)
            }
          }
        }
        video_url
      }
      series {
        slug
        title
        card_title
        category {
          name
        }
        author
        resource_date(formatString: "MMMM DD, YYYY")
        thumbnail {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body {
          data {
            childMarkdownRemark {
              rawMarkdownBody
              excerpt(format: HTML, pruneLength: 136)
            }
          }
        }
        resources {
          slug
        }
        videos_webinars_series {
          slug
        }
      }
    }
  }
`


export default TopicEntries;
